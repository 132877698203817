var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{attrs:{"id":"wx-header"}},[_c('div',{staticClass:"center"},[_c('div',{staticClass:"iconfont icon-return-arrow",on:{"click":function($event){return _vm.$router.back()}}},[_c('span',[_vm._v("返回")])]),_c('span',[_vm._v("聊天详情")])])]),_c('div',{staticClass:"member"},[_c('ul',{staticClass:"chat-dialogue-entry-collect"},[_vm._l((_vm.$route.query.msgInfo.user),function(item,index){return _c('li',{key:index},[_c('div',[_c('img',{attrs:{"src":item.headerUrl}})]),_c('p',[_vm._v(_vm._s(item.remark||item.nickname))])])}),_vm._m(0)],2)]),_c('div',{staticClass:"weui-cells"},[_vm._m(1),_c('div',{staticClass:"weui-cell weui-cell_switch"},[_c('div',{staticClass:"weui-cell__bd"},[_vm._v("消息免打扰")]),_c('div',{staticClass:"weui-cell__ft"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$route.query.msgInfo.quiet),expression:"$route.query.msgInfo.quiet"}],staticClass:"weui-switch",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.$route.query.msgInfo.quiet)?_vm._i(_vm.$route.query.msgInfo.quiet,null)>-1:(_vm.$route.query.msgInfo.quiet)},on:{"change":function($event){var $$a=_vm.$route.query.msgInfo.quiet,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$route.query.msgInfo, "quiet", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$route.query.msgInfo, "quiet", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$route.query.msgInfo, "quiet", $$c)}}}})])])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('span',{staticClass:"iconfont icon-chat-detail-add"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-cell weui-cell_switch"},[_c('div',{staticClass:"weui-cell__bd"},[_vm._v("置顶聊天")]),_c('div',{staticClass:"weui-cell__ft"},[_c('input',{staticClass:"weui-switch",attrs:{"type":"checkbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-cells"},[_c('div',{staticClass:"weui-cell weui-cell_access"},[_c('div',{staticClass:"weui-cell__bd"},[_vm._v("聊天文件")]),_c('div',{staticClass:"weui-cell__ft"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-cells"},[_c('div',{staticClass:"weui-cell weui-cell_access"},[_c('div',{staticClass:"weui-cell__bd"},[_vm._v("设置当前聊天背景")]),_c('div',{staticClass:"weui-cell__ft"})]),_c('div',{staticClass:"weui-cell weui-cell_access"},[_c('div',{staticClass:"weui-cell__bd"},[_vm._v("查找聊天内容")]),_c('div',{staticClass:"weui-cell__ft"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-cells"},[_c('div',{staticClass:"weui-cell"},[_c('div',{staticClass:"weui-cell__bd"},[_vm._v("清空聊天记录")]),_c('div',{staticClass:"weui-cell__ft"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weui-cells"},[_c('div',{staticClass:"weui-cell weui-cell_access"},[_c('div',{staticClass:"weui-cell__bd"},[_vm._v("投诉")]),_c('div',{staticClass:"weui-cell__ft"})])])
}]

export { render, staticRenderFns }